/* You can add global styles to this file, and also import other style files */

html {
    scroll-behavior: smooth;
}

h1,
h2 {
    font-family: "Playfair Display SC", serif;
}

p,
h3,
h4,
h5,
a {
    font-family: "Karla", sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #f1ec40;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #cec92c;
}
